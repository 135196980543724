import GET_USER from '../../graphql/queries/getUser';
import UPDATE_USER from '../../graphql/mutation/UpdateUser';
import GET_PROJECTS from '../../graphql/queries/getProjects';
import GET_APIS from '../../graphql/queries/getApis';
import { useQuery, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { Form, Button, Select, message, Spin } from 'antd';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const { Option } = Select;

const EditUser = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [selectedApis, setSelectedApis] = useState([]);
    const [selectedProjects, setSelectedProjects] = useState([]);
    const [selectedProjectId, setSelectedProjectId] = useState(null);
    const [initialApis, setInitialApis] = useState([]);
    const [nonSelectedApis, setNonSelectedApis] = useState([]);


    const { loading: loadingUser, data: userData } = useQuery(GET_USER, {
        fetchPolicy: 'network-only',
        variables: { id: id },
        onCompleted: (data) => {
            if (data && data.getUser) {
                const userApis = data.getUser.apis || [];
                setInitialApis(userApis);
                console.log(data.getUser.projects)
                setSelectedProjects(data.getUser.projects || []);
            }
        }
    });

    const { loading: loadingProjects, data: projectsData } = useQuery(GET_PROJECTS, {
        fetchPolicy: 'network-only',
    });

    const { loading: loadingApis, data: apisData } = useQuery(GET_APIS, {
        fetchPolicy: 'network-only',
        skip: !selectedProjectId, // Skip query execution if no project is selected
        variables: { projectid: selectedProjectId },
        onCompleted: (data) => {
            if (data && data.getApis) {
                const projectApis = data.getApis;
                console.log('projectApis', projectApis);
                // Filter user APIs to match the selected project
                const userProjectApis = projectApis.filter(projectApi =>
                    initialApis.includes(projectApi.id)
                );
                                // Filter out APIs from initialApis that are not in the projectApis
                const nonSelectedProjectApis = initialApis.filter(initialApiId =>
                    !projectApis.some(projectApi => projectApi.id === initialApiId)
                );

                


        // Store nonSelectedApis in state
        setNonSelectedApis(nonSelectedProjectApis);
                console.log('userProjectApis', userProjectApis);
                setSelectedApis(userProjectApis.map(api => api.id));
            }
        }
    });

    const [updateUser, { loading: updating }] = useMutation(UPDATE_USER, {
        onCompleted: () => {
            message.success('User updated successfully');
            navigate(-1); // Go back to the previous page
        },
        onError: (error) => {
            message.error(`Failed to update user: ${error.message}`);
        }
    });

    const handleFormSubmit = () => {
        form
            .validateFields()
            .then((values) => {
                 // Merge selectedApis and nonSelectedApis
            const allApis = [...selectedApis, ...nonSelectedApis];
            console.log('allApis', allApis);
            const allProjects = [...selectedProjects];
                updateUser({
                    variables: {
                        updateUserId: id,
                        apis: allApis,
                        projects: allProjects,
                        ...values
                    }
                });
            })
            .catch((info) => {
                console.log('Validate Failed:', info);
            });
    };

    const handleApiChange = (value) => {
        setSelectedApis(value);
    };

    const handleProjectChange = (value) => {
        // setSelectedProjects(value);
        setSelectedProjectId(value); // Update selected project ID to fetch APIs for this project
        setSelectedApis([]); // Reset selected APIs when project changes
    };

    if (loadingUser || loadingProjects || (loadingApis && selectedProjectId)) {
        return (
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh', // Full viewport height
          }}>
            <Spin size="large" />
          </div>
        );
      }
console.log('initialApis', initialApis);
// console.log('selectedProjects', selectedProjects);
    const availableApis = selectedProjectId ? apisData?.getApis || [] : initialApis;
    const ppp = projectsData?.getProjects || [];
    console.log('ppp', ppp);
    console.log('selectedProjects', selectedProjects);

    const availableProjects = ppp.filter(project =>
        selectedProjects.includes(project.id)
    );
    
    console.log('availiableprojects', availableProjects);

    return (
        <div style={{ padding: '24px' }}>
        <Form form={form} layout="vertical" onFinish={handleFormSubmit}>
            <Form.Item label="User Projects">
                <Select
                    value={selectedProjectId}
                    onChange={handleProjectChange}
                    placeholder="Select a Project"
                    style={{ width: '100%' }}
                    filterOption={(input, option) =>
                        option.children.toLowerCase().includes(input.toLowerCase())
                    }
                    allowClear
                >
                    {availableProjects.map((project) => (
                        <Option key={project.id} value={project.id}>
                            {project.name}
                        </Option>
                    ))}
                </Select>
            </Form.Item>
             
            <Form.Item label="User APIs">
                <Select
                    mode="multiple"
                    value={selectedApis}
                    onChange={handleApiChange}
                    placeholder="Select APIs"
                    style={{ width: '100%' }}
                    filterOption={(input, option) =>
                        option.children.toLowerCase().includes(input.toLowerCase())
                    }
                    allowClear
                >
                    {availableApis?.map((api) => (
                        <Option key={api.id} value={api.id}>
                            {api.name}
                        </Option>
                    ))}
                </Select>
            </Form.Item>
            
            <Form.Item>
                <Button type="primary" htmlType="submit" loading={updating}>
                    Update User
                </Button>
            </Form.Item>
        </Form>
        </div>
    );
};

export default EditUser;








































