import React, { useState } from "react";
import { Row, Col, Button, Select, message, Spin } from "antd";
import { useQuery, useMutation } from '@apollo/client';
import GET_PROJECTS from "../graphql/queries/getProjects";
import DELETE_BULK_API  from "../graphql/mutation/DeleteBulkApi";

const { Option } = Select;

const DeleteBulkApi = () => {
  const [project, setProject] = useState(null);
  const { loading: queryLoading, error: queryError, data } = useQuery(GET_PROJECTS);
  const [deleteBulkApi, { loading: mutationLoading, error: mutationError }] = useMutation(DELETE_BULK_API);
  const  Projects = data?.getProjects || [];

  const handleDelete = async () => {
    try {
      await deleteBulkApi({ variables: { project } });
      message.success('API deleted successfully');
    } catch (err) {
      message.error('Failed to delete API');
    }
  };

  if (queryLoading) return <Spin size="large" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }} />;
  if (queryError) return <p>Error fetching projects: {queryError.message}</p>;

  return (
    <>
    <div className="form-container">
      <h1>Delete Bulk API</h1>
  
       <Row
      justify="center"
      align="middle"
      style={{ height: "100vh" }}
    >
      <Col>
        <div style={{ textAlign: "center" }}>
          <Select
            placeholder="Select a project"
            value={project}
            onChange={setProject}
            style={{ marginBottom: 20, width: 200 }}
          >
            {Projects?.map(proj => (
              <Option key={proj.id} value={proj.id}>{proj.name}</Option>
            ))}
          </Select>
          <Button
            type="primary"
            onClick={handleDelete}
            loading={mutationLoading}
            disabled={!project}
          >
            Delete Bulk API
          </Button>
          {mutationError && <p style={{ color: 'red' }}>{mutationError.message}</p>}
        </div>
      </Col>
    </Row>
    </div>
    </>
 
  );
};

export default DeleteBulkApi;
