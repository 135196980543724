import { gql } from "@apollo/client";

export default gql 
`
mutation CreateUser($user: UserInfo) {
  CreateUser(user: $user) {
    email
    name
    phone
    username
    usertype
    region
    details
  }
}

`