import React from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";

import Login from "./Pages/login";
import Home from "./Pages/home";
import Profile from "./Pages/profile";
import CreateUserForm from "./Pages/Users/createUser";
import Createproject from "./Pages/Projects/createProject";
import DeleteBulkApi from "./Pages/deleteBulkApi";
import ProjectsPage from "./Pages/Projects/project";
import Layout from "./Layout/layout";
import User from "./Pages/Users/user";
import AddProject from "./Pages/Users/addProject";
import ProjectApis from "./Pages/Projects/ProjectApis";
import ProjectOverview from "./Pages/Projects/projectOverview";
import ProjectApiSelector from "./Pages/Projects/ProjectApiSelector";
import EditApi from "./Pages/Projects/editIndividualApi";
import SHC from "./Pages/shc";
import EditUser from "./Pages/Users/editUser";


// import AddApis from "./Pages/Users/addApis";

const LayoutWrapper = ({ children }) => {
  const location = useLocation();
  
  // Only render Layout for routes other than "/"
  if (location.pathname === "/" || location.pathname === "/shc") {
    return children;
  }

  return <Layout>{children}</Layout>;
};

const Routers = () => {
  return (
    <Router>
      <LayoutWrapper>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/testapis" element={<Home />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/users" element={<User />} />
          <Route path="/edituser/:id" element={<EditUser />} />
          <Route path="/createuser" element={<CreateUserForm />} />
          <Route path="/projects" element={<ProjectsPage />} />
          <Route path="/createProject" element={<Createproject />} />
          <Route path="/deleteBulkApi" element={<DeleteBulkApi />} />
          <Route path="/addProject/:id" element={<AddProject />} /> {/* Modified Route */}
          <Route path="/addApisToProjects" element={<ProjectApis />} />
          <Route path="/projects/:projectId" element={<ProjectOverview />} />
          <Route path="/projectApiSelector" element={<ProjectApiSelector />} />
          <Route path="/edit-api/:apiId" element={<EditApi />} />
          <Route path="/shc" element={<SHC />} />
          

        </Routes>
      </LayoutWrapper>
      
    </Router>
  );
};

export default Routers;
