import { gql } from "@apollo/client";

export default gql
`
query GetApi($getApiId: ID) {
  getApi(id: $getApiId) {
    name
    query
    project
    details
    id
    fields
    variables
    info
  }
}`