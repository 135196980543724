import React from 'react';
import { useMutation } from '@apollo/client';
import { Form, Input, Button, message } from 'antd';
import CREATE_PROJECT from '../../graphql/mutation/createProject';
import { useNavigate } from 'react-router-dom';

import './createProject.css'

const CreateProject = () => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [createProject, { loading, error }] = useMutation(CREATE_PROJECT);

    const onFinish = async (values) => {
        try{
            await createProject({ variables: { input: values } })
            message.success('Project created successfully')
            form.resetFields();
            navigate('/projects')
        }catch(err){
            console.error('Error creating project:', err);
            message.error( 'Error creating project')
        }
        
    };

    return (
        <div className='form-container'>
  
             <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
            >
                <Form.Item
                    name="name"
                    label="Project Name"
                    rules={[{ required: true, message: 'Please enter the project name' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="description"
                    label="Project Description"
                >
                    <Input.TextArea rows={4} />
                </Form.Item>
                <Form.Item
                    name="url"
                    label="Project URL"
                    rules={[{ type: 'url', message: 'Please enter a valid URL' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={loading}>
                        Create Project
                    </Button>
                </Form.Item>
            </Form>
            {error && <p style={{ color: 'red' }}>Error: {error.message}</p>}
        </div>
    );
};

export default CreateProject;
