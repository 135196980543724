import { gql } from "@apollo/client";
export default gql
`
query GetUsers($projects: ID) {
  getUsers(projects: $projects) {
    id
    username
   
    name
    email
    phone
   
    region
    details
    usertype
   
    createdAt
    updatedAt
    apis
    projects
  }
} `