import React, { useState, useEffect } from "react";
import { Form, Input, Button, message ,Spin} from "antd";
import { useQuery, useMutation } from "@apollo/client";
import { useParams, useNavigate } from "react-router-dom";
import GET_API from "../../graphql/queries/getApi";
import UPDATE_API from "../../graphql/mutation/UpdateApi";
import TextEditor from "../../Layout/texteditor";

const { TextArea } = Input;

const EditApi = () => {
    const { apiId } = useParams();  // Get the API ID from the URL
    const navigate = useNavigate();

    const [form] = Form.useForm();
    const [details, setDetails] = useState("");
    const flag = false;

    const { loading: loadingApi, data: apiData } = useQuery(GET_API, {
        fetchPolicy: 'network-only', // Ensure fresh data is fetched

        variables: { getApiId: apiId },
        onCompleted: (data) => {
             if(data && data.getApi) {
                // console.log("apiData", data);
                setDetails(data.getApi.info); // Pre-fill the textarea with the current details
             }
        }
    });
console.log('details', details)
    const [updateApi, { loading: updating }] = useMutation(UPDATE_API, {
        onCompleted: () => {
            message.success("API details updated successfully!");
            navigate(-1); // Go back to the previous page
        },
        onError: (error) => {
            message.error(`Error updating API: ${error.message}`);
        }
    });

    const handleSave = () => {
       
            console.log(details)
            // const parsedDetails = JSON.parse(details); // Ensure the JSON is valid
            // console.log('details', parsedDetails)

            // Prepare the update input using the existing data
            const updateInput = {
                id: apiData.getApi.id,  // Preserve the existing id
                name: apiData.getApi.name,  // Preserve the existing name
                type: apiData.getApi.type,  // Preserve the existing type
                project: apiData.getApi.project,  // Preserve the existing project
                query: apiData.getApi.query,  // Preserve the existing query type
                variables: apiData.getApi.variables,  // Preserve the existing variables
                fields: apiData.getApi.fields,  // Preserve the existing fields
                info: details  // Update only the details field
            };

            // Perform the update
            updateApi({
                variables: {updateApiId: apiId, input: updateInput }
            });
        
    };

    if (loadingApi) return(
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh', // Full viewport height
          }}>
            <Spin size="large" />
          </div>
    );

    return (
    <>
       
        
        <Form form={form} layout="vertical" onFinish={handleSave}>
            <Form.Item label="API Details" name="details" >
            <span style={{ fontWeight:'500'}}>{apiData.getApi.name}</span>
                
            
            <TextEditor
                value={details} // Pass the value of the description
                onChange={(content) => setDetails(content)} // Handle the change event
                placeholder="Enter API details" // Provide a placeholder for the input
           />
            </Form.Item>
            {/* <div dangerouslySetInnerHTML={{ __html: details }} />  */}
            <Form.Item>
                <Button type="primary" htmlType="submit" loading={updating}>
                    Save Changes
                </Button>
            </Form.Item>
        </Form>
        
    </>
      
    );
};

export default EditApi;
