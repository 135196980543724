import { gql } from "@apollo/client";

export default gql
`query GetUser($id: String, $username: String) {
  getUser(_id: $id, username: $username) {
    projects
    apis
    id
    name
    username
    email
    details
    region
  }
}
`