import { gql } from "@apollo/client";

export default gql
` 

mutation UploadApi($input: JSON!, $projectsId: String) {
  UploadApi(input: $input, projectsId: $projectsId) {
    success
    message
  }
}
 `