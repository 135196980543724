import { gql } from "@apollo/client";

export default gql
`query GetApis($projectid: ID) {
  getApis(projectid: $projectid) {
    id
    name
    query
    variables
    fields
    project
    details
    info
  }
}
 
`