import React, { useState } from "react";
import { Select, Spin } from "antd";
import { useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import GET_PROJECTS from "../../graphql/queries/getProjects";
import GET_APIS from "../../graphql/queries/getApis";

const { Option } = Select;

const ProjectApiSelector = () => {
    const navigate = useNavigate();
    const [selectedProjectId, setSelectedProjectId] = useState(null);
    const [selectedApiId, setSelectedApiId] = useState(null);

    const { loading: loadingProjects, data: projectsData } = useQuery(GET_PROJECTS,{  fetchPolicy: 'network-only', // Ensure fresh data is fetched
    });
    const { loading: loadingApis, data: apisData, refetch: refetchApis } = useQuery(GET_APIS, {
        fetchPolicy: 'network-only', // Ensure fresh data is fetched

        skip: !selectedProjectId,
        variables: { projectid: selectedProjectId }
    });

    const Projects = projectsData?.getProjects;
    const Apis = apisData?.getApis;
    // Handle project selection
    const handleProjectChange = (projectId) => {
        setSelectedProjectId(projectId);
        refetchApis({ projectId }); // Fetch APIs for the selected project
    };

    // Handle API selection
    const handleApiChange = (apiId) => {
        setSelectedApiId(apiId);
        navigate(`/edit-api/${apiId}`); // Navigate to the API edit page
    };

    return (
        <div>
            <Select
                style={{ width: "50%",marginTop: 20 }}
                placeholder="Select Project"
                loading={loadingProjects}
                onChange={handleProjectChange}
            >
                {Projects?.map(project => (
                    <Option key={project.id} value={project.id}>
                        {project.name}
                    </Option>
                ))}
            </Select>

            {selectedProjectId && (
                <Select
                    style={{ width: "60%", marginTop: 20 }}
                    placeholder="Select API"
                    showSearch
                    loading={loadingApis}
                    onChange={handleApiChange}
                    filterOption={(input, option) =>
                        option.children.toLowerCase().includes(input.toLowerCase())
                    }
                    notFoundContent={loadingApis ? <Spin size="small" /> : "No APIs found"}
                >
                    {Apis?.map(api => (
                        <Option key={api.id} value={api.id}>
                            {api.name}
                        </Option>
                    ))}
                </Select>
            )}
        </div>
    );
};

export default ProjectApiSelector;
