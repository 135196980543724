import { gql } from "@apollo/client";

export default gql
`mutation UpdateApi($updateApiId: ID, $input: ApiInput) {
  UpdateApi(id: $updateApiId, input: $input) {
    details
    name
    id
    project
    fields
    query
    variables
    info
  }
}
`