import React from 'react';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import BulkUpload from '../bulkupload'; // Adjust the import path if necessary

const ProjectApis = () => {
  const navigate = useNavigate();

  return (
    <div style={{ padding: '24px' }}>
      

      <BulkUpload />

      <div style={{ padding: '20px' }}>
        <Button
          type="primary"
          onClick={() => navigate('/deleteBulkApi')}
        >
          Delete Bulk APIs
        </Button>
      </div>
    </div>
  );
};

export default ProjectApis;
