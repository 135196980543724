import { gql } from "@apollo/client";

export default gql
`
query GetProject($getProjectId: ID) {
  getProject(id: $getProjectId) {
    name
    id
    url
    description
  }
}`