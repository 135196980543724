import React from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { Card, Spin, Typography, Button, message } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import GET_PROJECT from '../../graphql/queries/getProject'; // Import the GET_PROJECT query
import DELETE_PROJECT from '../../graphql/mutation/DeleteProject'; // Import the DELETE_PROJECT mutation

const { Title, Paragraph } = Typography;

const ProjectOverview = () => {
  const { projectId } = useParams(); // Retrieve projectId from the URL
  const navigate = useNavigate();

  // Fetch project details
  const { loading, error, data } = useQuery(GET_PROJECT, {
    fetchPolicy: 'network-only', // Ensure fresh data is fetched

    variables: { getProjectId: projectId },
  });

  // Mutation to delete the project
  const [deleteProject, { loading: deleteLoading }] = useMutation(DELETE_PROJECT, {
    onCompleted: () => {
      message.success('Project deleted successfully!');
      navigate('/projects'); // Redirect after deletion
    },
    onError: (err) => {
      message.error(`Error: ${err.message}`);
    },
  });

  if (loading) return(
  
  <div style={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh', // Full viewport height
  }}>
    <Spin size="large" />
  </div>
  
);
  // if (error) return <p>Error: {error.message}</p>;

  const { name, description, url } = data.getProject;

  // Handle the delete project action
  const handleDeleteProject = () => {
    deleteProject({
      variables: { deleteProjectId: projectId },
    });
  };

  return (
    <Card title="Project Overview" style={{ width: '100%', padding: '24px' }}>
      <Title level={2}>{name}</Title>
      <Paragraph>{description}</Paragraph>
      <Paragraph>
        <strong>URL: </strong>
        <a href={url} target="_blank" rel="noopener noreferrer">
          {url}
        </a>
      </Paragraph>
      
      {/* Delete Project Button */}
      <Button
        type="primary"
        danger
        onClick={handleDeleteProject}
        loading={deleteLoading}
        style={{ marginTop: '16px' }}
      >
        Delete Project
      </Button>
    </Card>
  );
};

export default ProjectOverview;
