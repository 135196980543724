import React, { useEffect, useState } from 'react';
import './SHC.css';

const SHC = () => {
  const [startAnimation, setStartAnimation] = useState(false);

  useEffect(() => {

     
    const animationTimer = setTimeout(() => {
      setStartAnimation(true);
    }, 2000);

    return () => clearTimeout(animationTimer);
  }, []);


  useEffect(() => {
    const handleScroll = () => {
      const buttons = document.querySelectorAll('.button');
      buttons.forEach(button => {
        const rect = button.getBoundingClientRect();
        if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
          button.classList.add('visible');
        } else {
          button.classList.remove('visible');
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Run once on mount to show initial buttons

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="shc-wrapper">
      {/* <div className={`shc-container ${startAnimation ? 'shrink' : ''}`}>
        <div className="shc-text">
          <span className={`get-text ${startAnimation ? 'grow' : ''}`}>Get&nbsp;</span>
          <span className={`shc-main-text ${startAnimation ? 'shrink-text' : ''}`}> Soil Health Card</span>
        </div>
      </div> */}
       <div className={`shc-container ${startAnimation ? 'shrink' : ''}`}>
      <div className="shc-text">
        <span className={`get-text`}>Get&nbsp;</span>
        <span className={`shc-main-text`}> Soil Health Card</span>
      </div>
    </div>
      <div className={`homepage-content ${startAnimation ? 'visible' : ''}`}>
        {/* Homepage content goes here */}
        <header className="header">
          <div className="logo">My Logo</div>
        </header>
        
        <section className="banner">
        <video autoPlay loop muted>
            <source src="https://soilhealth.dac.gov.in/files/videos/28672-370964624_small.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          {/* <div className="banner-content">
            <div className="banner-title">Welcome to My Site</div>
            <div classN ame="banner-description">This is a description or subtitle</div>
          </div> */}
        </section>
        <section className="icons-section">
        <button class="button">Hover Me</button>
        <button class="button">Hover Me</button>
        <button class="button">Hover Me</button>
        <button class="button">Hover Me</button>
        <button class="button">Hover Me</button>
        <button class="button">Hover Me</button>
        
        </section>
      </div>
    </div>
  );
};

export default SHC;
