import React, { useEffect, useState } from 'react';
 
 
import { Button, Form, Input, Select, Row, Col, Avatar, Layout, message, Menu, Dropdown,Checkbox} from 'antd';
import { UserOutlined,ProjectOutlined,FileAddOutlined,PlayCircleOutlined,PlusOutlined, MenuUnfoldOutlined, MenuFoldOutlined   } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

 
import logo from '../rlogo.png';
import Me from '../graphql/queries/Me';
import './layout.css';
 

 

// import CreateProjectpage from '../Pages/Projects/createProject';
 

const { Header, Footer, Sider, Content } = Layout;
const { SubMenu } = Menu;

const headerStyle = {
  color: '#fff',
  height: 64,
  paddingInline: 48,
  lineHeight: '64px',
  backgroundColor: '#4096ff',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
};

const leftContainerStyle = {
  display: 'flex',
  alignItems: 'center',
};
const contentStyle = {
 
 height:"100vh",
  
};
const siderStyle = {
  textAlign: 'center',
  lineHeight: '120px',
  color: '#fff',
  // backgroundColor: '#f2f2f2',
  borderRight: '1px solid #6c6c6c',
  height: '100vh', 
  backgroundColor: '#f0f2f5',
  paddingTop: '16px',
};
const footerStyle = {
  textAlign: 'center',
  color: '#fff',
  backgroundColor: '#4096ff',
};
const layoutStyle = {
  borderRadius: 8,
//   overflow: 'hidden',
//   width: 'calc(50% - 8px)',
//   maxWidth: 'calc(50% - 8px)',
};
 
    const  {Option,OptGroup} = Select;

  
 
    const primitiveTypes = ['String', 'JSON', 'Int', 'Float','ID','Datetime','Boolean','SafeString','EmailAddress'];


    const CommonLayout = ({ children }) => {

      const [collapsed, setCollapsed] = useState(false);

      const [animate, setAnimate] = useState(false);

      const toggleCollapsed = () => {
        setAnimate(true);
        setTimeout(() => {
          setCollapsed(!collapsed);
          setAnimate(false);
        }, 100);
      };

const [meData, setMeData] = useState({});

        useEffect(() => {
    
            const data = sessionStorage.getItem('data');
            setMeData(JSON.parse(data));
        
        //console.log('data', data);
        },[])
   const navigate = useNavigate();
     
      const handleMenuClick = ({ key }) => {
        if (key === 'profile') {
          navigate('/profile'); // Navigate to profile page
        } else if (key === 'logout') {
          sessionStorage.clear(); // Clear session storage
          navigate('/'); // Navigate to login page or home page
        }
      };
    
      const menu = (
        <Menu onClick={handleMenuClick}>
          <Menu.Item key="profile">Profile</Menu.Item>
          <Menu.Item key="logout">Logout</Menu.Item>
        </Menu>
      );
    
  
    return (


<Layout style={layoutStyle}>
<Header style={headerStyle}>
      <div style={leftContainerStyle}>
        <div style={{height: '50px', width: '50px', borderRadius: '50%', backgroundColor: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <img src={logo} alt="Logo" style={{ height: '32px' }} />
        </div>
        
        <div style={{ color: '#fff', fontSize: '1.2rem', marginLeft: '20px', fontWeight: 'bold',fontWeight: '550' }}>API Documentation</div>
      </div>
      <Dropdown overlay={menu}>
        <Avatar style={{ backgroundColor: '#87d068', cursor: 'pointer' }} icon={<UserOutlined />} />
      </Dropdown>
    </Header>
      <Layout>
      <Sider 
      // collapsible
     
      collapsed={collapsed}
      onCollapse={toggleCollapsed}
      width="17%" 
      style={{ background: '#fff',height:'100vh',overflowY:'auto',position:'sticky',top:0 }}>
         <div className="toggle-button" onClick={toggleCollapsed} style={{ padding: '10px', cursor: 'pointer', background: '#f0f2f5',textAlign:'right' }}>
          {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        </div>
      <Menu
        mode="inline"
        defaultSelectedKeys={['projects']}
        style={{ height: '100%', borderRight: 0 }}
      >
      {  meData?.me?.usertype === 'SUPERADMIN' &&
      <>
        <Menu.Item
          key="projects"
          icon={<FileAddOutlined />}
          className={`menu-item delay-1 ${animate && collapsed ? 'exit' : ''}`}

          onClick={() => navigate('/projects')}
        >
          Projects
        </Menu.Item>
        
        {/* <Menu.Item
        key="projectApiSelector"
        icon={<UserOutlined />}
        onClick={() => navigate('/projectApiSelector')}
        >
          Add details to API
        </Menu.Item>
        <Menu.Item
        key="addApisToProjects"
        icon={<UserOutlined />}
        onClick={() => navigate('/addApisToProjects')}
        >
          Add Apis To Projects
        </Menu.Item> */}
         <SubMenu
    key="subMenuApis"
    icon={<PlusOutlined />}
    title="Manage APIs"
    className={`menu-item delay-2 ${animate && collapsed ? 'exit' : ''}`}

  >
    <Menu.Item
      key="projectApiSelector"
      className={`menu-item delay-3 ${animate && collapsed ? 'exit' : ''}`}

      onClick={() => navigate('/projectApiSelector')}
    >
      Add details to API
    </Menu.Item>
    <Menu.Item
      key="addApisToProjects"
      className={`menu-item delay-4 ${animate && collapsed ? 'exit' : ''}`}

      onClick={() => navigate('/addApisToProjects')}
    >
      Add APIs to Projects
    </Menu.Item>
    
  </SubMenu>
        <Menu.Item
          key="users"
          icon={<UserOutlined />}
          className={`menu-item delay-5 ${animate && collapsed ? 'exit' : ''}`}

          onClick={() => navigate('/users')}
        >
          Users
        </Menu.Item>

        
        </>
    }
        <Menu.Item
          key="testapis"
          icon={<PlayCircleOutlined />}
          className={`menu-item delay-6 ${animate && collapsed ? 'exit' : ''}`}

          onClick={() => navigate('/testapis')}
        >
          Test Apis
        </Menu.Item>
      </Menu>
    </Sider>
        <Content  style={contentStyle}>
 
        <Content style={contentStyle}>
          {children}
        </Content>
 

</Content>
      </Layout>
      {/* <Footer style={footerStyle}>Footer</Footer> */}


</Layout>

    );
  };
  
  
  export default CommonLayout;