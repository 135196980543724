import React, { useEffect, useState } from 'react';
 

import { useQuery } from '@apollo/client';  
import { Button, Form, Input, Select, message,Checkbox,Row,Col} from 'antd';
import { useNavigate } from 'react-router-dom';

import GET_PROJECTS from '../graphql/queries/getProjects';
import GET_APIS from '../graphql/queries/getApis';
import { CopyOutlined } from '@ant-design/icons';

const  {TextArea} = Input;
  
// import CreateProjectpage from '../Pages/Projects/createProject';
const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text).then(() => {
    message.success('Copied to clipboard!');
  }).catch(() => {
    message.error('Failed to copy!');
  });
};

 
 
    const  {Option,OptGroup} = Select;

  
 
    const primitiveTypes = ['String', 'JSON', 'Int', 'Float','ID','Datetime','Boolean','SafeString','EmailAddress'];


const Home = () => {
    const [form] = Form.useForm();
 
    const [selectedProject, setSelectedProject] = useState();
    const [projectUrl, setProjectUrl] = useState();
    const [queries, setQueries] = useState([]);
    const [selectedQuery, setSelectedQuery] = useState();
    const [variables, setVariables] = useState({});
    const [constructedQuery, setConstructedQuery] = useState('');
    const [inputVariablesString, setInputVariablesString] = useState('');
    const [primitiveFields, setPrimitiveFields] = useState([]);
    const [complexFields, setComplexFields] = useState([]);
    const [selectedPrimitiveFields, setSelectedPrimitiveFields] = useState([]);
    const [selectedComplexFields, setSelectedComplexFields] = useState([]);
    const [querydetails,setqueryDetails]=useState({});
    const [token, setToken] = useState('');
     const [apiResponse, setApiResponse] = useState('');



    const { data, error, loading } = useQuery(GET_PROJECTS ,{ fetchPolicy: 'network-only'} // Ensure fresh data is fetched
    );  
     
    const {data: apidata} = useQuery(GET_APIS, {fetchPolicy: 'network-only',variables: {projectid: selectedProject}});

     

//console.log('projecturl',projectUrl)
    const fetcher = (graphQLParams) => {
      return fetch(projectUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(graphQLParams),
      }).then(response => response.json());
    
    };
    const handleRunQuery = async () => {
      try {
        const variablesParsed = inputVariablesString ? JSON.parse(inputVariablesString) : {};
        const response = await fetcher({ query: constructedQuery, variables: variablesParsed });
        setApiResponse(JSON.stringify(response, null, 2)); // Format the response for display
        message.success('Query executed successfully!');
        console.log('GraphQL Response:', response);
      } catch (error) {
        message.error('Error executing query.');
        // setApiResponse(JSON.stringify(error, null, 2));
        console.error('Error:', error);
      }
    };



   
      
   

useEffect(() => {
  if(apidata){
    setQueries(apidata.getApis);
  }
},[apidata])
 
//console.log("queries",queries)
     const Projects = data?.getProjects;
console.log("Projects",Projects)
   
 

    const handleProjectChange = (value) => {
      const selectedProject = Projects.find(project => project.id === value);
      if (selectedProject) {
        setSelectedProject(value);
        setProjectUrl(selectedProject.url || ''); // Set the project URL
      } 
      setVariables({})
      setConstructedQuery('');
      setInputVariablesString('');
      setPrimitiveFields([]);
      setComplexFields([]);
    };
      const handleTokenChange = (e) => {
        setToken(e.target.value);
      };


 
  
    const handleQueryChange = (value) => {
      
      const query = queries.find((query) => query.name === value);
      console.log('query', query);
 setqueryDetails(query.info);
      setSelectedQuery(query);
      setVariables({})
      setConstructedQuery('');
      setInputVariablesString('');
      setPrimitiveFields([]);
      setComplexFields([]);
    };
  
  
   
    

      const buildVariablesString = () => {
        const variableDeclarations = selectedQuery.variables.map(variable => {
          if (variable.required === true) {
            return `$${variable.name}: ${variable.type}!`;
          } else {
            return `$${variable.name}: ${variable.type}`;
          }
        });
        return variableDeclarations.join(', ');
      };
    
      const buildVariablesMapping = () => {
        return selectedQuery.variables.map(variable => {
          if (variable.type === 'PlotInput') {
            return `${variable.name.replace(/\./g, '_')}: $${variable.name.replace(/\./g, '_')}`;
          } else {
            return `${variable.name}: $${variable.name}`;
          }
        }).join(', ');
      };
    



    const buildInputVariablesObject = (variables) => {
      console.log(variables)
      const buildNestedObject = (keys, value, type) => {
        if (keys.length === 0) {
        
          // Check the type and return the value correctly formatted
          switch (type) {
            case 'String':
            case 'SafeString':
            case 'ID':
          
              return `${value}`
            case 'Int':
            case 'Float':
            
            
              return parseFloat(value);
              case 'Boolean':
                return value===true  ? true: false;

              case 'JSON':
                try {
                  return JSON.parse(value);
                } catch (e) {
                  // Handle JSON parsing errors if needed
                  console.error('Invalid JSON format:', e);
                  return null; // or handle the error appropriately
                }
            default:
              return value;
          }
        }
        //console.log(value)
        return { [keys[0]]: buildNestedObject(keys.slice(1), value, type) };
      };
    
      let result = {};
      for (let key in variables) {
        const keys = key.split('.');
        const { value, type } = variables[key];
        console.log('value',value,type)
        const nestedObject = buildNestedObject(keys, value, type);
        console.log('nestedObject',nestedObject)
        result = deepMerge(result, nestedObject);
      }
      //console.log('result',result)
      return result;
    };
    
    const deepMerge = (target, source) => {
      for (const key of Object.keys(source)) {
        if (source[key] instanceof Object && key in target) {
          Object.assign(source[key], deepMerge(target[key], source[key]));
        }
      }
      return { ...target, ...source };
    };
    
    



      const constructQuery = () => {
        if (!selectedQuery) return '';
    console.log('tadan',variables)
        const variablesString = buildVariablesString();
        const inputVariablesObject = buildInputVariablesObject(variables);
        const inputVariablesString = JSON.stringify(inputVariablesObject, null, 2);
        const variablesMapping = buildVariablesMapping();
         // Helper function to format fields with nested structures
         const formatFields = (fields, indent = '') => {
          //console.log('fields', fields)
          // Group fields by their type
          const groupedFields = fields.reduce((acc, field) => {
            if(primitiveTypes.includes(field.type) || field.type === undefined) {
              // Primitive field
              acc.primitives.push(field);
            } else {
              // Complex field
              if (!acc.complex[field.type]) {
                acc.complex[field.type] = [];
              }
              acc.complex[field.type].push(field);
            }
            return acc;
          }, { primitives: [], complex: {} });
         
        
          // Format primitive fields directly
          const formattedPrimitives = groupedFields.primitives.map(field => `              ${indent}${field.name}`).join('\n');
      //console.log('w',groupedFields.complex)
          // Format complex fields
          const formattedComplex = Object.keys(groupedFields.complex).map(type => {
            //console.log('type',type)
            const fields = groupedFields.complex[type];
            const nestedIndent = `${indent}  `;
            const nestedFields = fields.map(field => {
              const innerFields = field.fields && field.fields.length > 0
                ? `{\n${formatFields(field.fields, nestedIndent).join('\n')}\n${indent}}`
                : '';
              return `              ${nestedIndent}${field.name}${innerFields}`;
            }).join('\n');
            return `              ${indent}${type} {\n${nestedFields}\n${indent}          }`;
          }).join('\n');
        
          // Combine formatted primitives and complex fields, making sure primitives are directly output
          return [formattedPrimitives, formattedComplex].filter(Boolean).join('\n');
        };
        
        
  


  // Combine selected primitive and complex fields
  const combinedFields = [
    ...selectedPrimitiveFields.map(name => ({ name})),
    ...selectedComplexFields.flatMap(name => {
      // Find the complex field by its name
      const complexField = selectedQuery.fields.find(f => f.fields.some(field => field.name === name));

      if (complexField) {
        // Find the specific nested field
        const nestedField = complexField.fields.find(field => field.name === name);
        return nestedField ? [{
          name: name,
          type: complexField.name,
          fields: nestedField.fields || []
        }] : [];
      }
      return [];
    })
  ];
  //console.log('combinedFields',combinedFields)

  const fieldsString = combinedFields.length > 0 ? formatFields(combinedFields) : '';
  //console.log('fieldsString',fieldsString)
    
  const queryType = selectedQuery.query === 'mutation' ? 'mutation' : 'query';

  let queryString;
  console.log('slectedQuery',selectedQuery)
  console.log('variablesMapping',variablesMapping)

  if (selectedQuery.fields.length > 0 && selectedQuery.variables.length > 0) {
    console.log('bb')
    queryString = `
      ${queryType} ${selectedQuery.name}(${variablesString}) {
        ${selectedQuery.name}(${variablesMapping}) {
          ${fieldsString.trim().replace(/\n\s*\n/g, '\n')}
        }
      }
    `;
  } 
  else if(selectedQuery.variables.length === 0 && selectedQuery.fields.length > 0) {
    console.log('bbdj')
    queryString = `
      ${queryType} ${selectedQuery.name} {
        ${selectedQuery.name} {
        ${fieldsString.trim().replace(/\n\s*\n/g, '\n')}
        }
      }
    `;
  }
  else  {
    console.log('b')
    queryString = `
      ${queryType} ${selectedQuery.name}(${variablesString}) {
        ${selectedQuery.name}(${variablesMapping})
      }
    `;
  }
    
        setConstructedQuery(queryString);
        setInputVariablesString(inputVariablesString);
        return queryString;
      };
      
      const handleVariableChange = (variableName, value, variableType) => {
        console.log('variableName', variableName);
        console.log('value', value);
        console.log('variableType', variableType);
        
        setVariables({
          ...variables,
          [variableName]: { value, type: variableType },
        });
      };
      //console.log('hiii',variables)
     



      const renderVariableInput = (variable) => {
        //console.log('variable', variable);
      
        // Add a required indicator if the variable is required
        const getLabel = (name, required) => (
          <span >
            <strong style={{marginLeft:'60px', fontWeight:'500'}}>{name}</strong>
            {required && <span style={{ color: 'red' }}> *</span>}
          </span>
        );


        const getLabelforcomplex = (name, required) => (
          <span >
            <strong style={{fontSize: '28px', fontWeight:'600',}}>{name}</strong>
            {required && <span style={{ color: 'red' }}> *</span>}
          </span>
        );
      
        switch (variable.type) {
          case 'String':
          case 'SafeString':
          case 'ID':
          case 'Datetime':
          case 'PhoneNumber':

          return (
            <div key={variable.name}>
              {getLabel(variable.name, variable.required)}:
              <Input
                type="text"
                style={{ width: '150px', marginLeft: '10px' }}
                onChange={(e) => handleVariableChange(variable.name, e.target.value, variable.type)}
                placeholder={variable.type}
                
                required={variable.required} // Set required attribute if the variable is required
 
              />
            </div>
          );
          case 'JSON':
            return (
              <div key={variable.name}>
                {getLabel(variable.name, variable.required)}:
                <TextArea
                  type="text"
                  style={{ width: '150px', marginLeft: '10px' }}
                  onChange={(e) => handleVariableChange(variable.name, e.target.value, variable.type)}
                  placeholder={variable.type}
                  
                  required={variable.required} // Set required attribute if the variable is required
                  autoSize={{ minRows: 2, maxRows: 6 }}  // Enable auto-resize with min and max rows

                />
              </div>
            );
          case 'Int':
            return (
              <div key={variable.name}>
                {getLabel(variable.name, variable.required)}:
                <Input
                  type="number"
                  style={{ width: '150px', marginLeft: '10px' }}
                  onChange={(e) => handleVariableChange(variable.name, parseInt(e.target.value, 10), variable.type)}
                  placeholder="Enter an integer"
                  required={variable.required}
                />
              </div>
            );
          case 'Float':
            return (
              <div key={variable.name}>
                {getLabel(variable.name, variable.required)}:
                <Input
                  type="number"
                  step="any"
                  style={{ width: '150px', marginLeft: '10px' }}
                  onChange={(e) => handleVariableChange(variable.name, parseFloat(e.target.value), variable.type)}
                  placeholder="Enter a float"
                  required={variable.required}
                />
              </div>
            );
          case 'Boolean':
            return (
              <div key={variable.name}>
                {getLabel(variable.name, variable.required)}:
                <Checkbox
                  style={{ marginLeft: '10px' }}
                  onChange={(e) => handleVariableChange(variable.name, e.target.checked, variable.type)}
                  // checked={}
                >
                  {variable.name}
                </Checkbox>
              </div>
            );
          default:
            // For complex types, render nested input fields
            return (
              <div key={variable.name} style={{ marginTop: '15px' }}>
              {getLabelforcomplex(variable.name, variable.required)}
              {variable.fields.map((field) => (
                <div key={field.name} style={{ marginTop: '5px' }}>
                  <span>{getLabel(field.name, field.required)}:</span>
                  {field.type === 'Boolean' ? (
                    <Input
                      type="checkbox"
                      style={{ marginLeft: '10px' }}
                      onChange={(e) => handleVariableChange(`${field.name}`, e.target.checked, field.type)}
                      // checked={}
                      required={field.required}
                    />
                  )
                  : field.type === 'JSON' ? (
                   
                     
                      
                      <TextArea
                        type="text"
                        style={{ marginLeft: '100px' }}
                        onChange={(e) => handleVariableChange(`${variable.name}.${field.name}`, e.target.value, field.type)}
                        placeholder={field.type}
                        
                        required={variable.required} // Set required attribute if the variable is required
                        autoSize={{ minRows: 1, maxRows: 9 }}  // Enable auto-resize with min and max rows
      
                      />
                  
                 
                ) :
                   (
                    <Input
                      type={field.type === 'Int' || field.type === 'Float' ? 'number' : 'text'}
                      style={{ width: '150px', marginLeft: '10px' }}
                      onChange={(e) => handleVariableChange(`${variable.name}.${field.name}`, e.target.value, field.type)}
                      placeholder={field.type}
                      required={field.required}
                    />
                  )}
                </div>
              ))}
            </div>
            
            );
        }

     
        
      };
      
      

      useEffect(() => {
        if (selectedQuery) {
          const primitives = selectedQuery.fields.filter(field => primitiveTypes.includes(field.type));
          const complexes = selectedQuery.fields.filter(field => !primitiveTypes.includes(field.type));
          setPrimitiveFields(primitives);
          setComplexFields(complexes);
        }
      }, [selectedQuery]);
    
      const handlePrimitiveFieldChange = (values) => {
        // Update the selected primitive fields
        setSelectedPrimitiveFields(values);
    
        // Ensure selected primitive fields are not included in complex field selections
        const updatedComplexFields = complexFields.flatMap(field =>
          field.fields.filter(nestedField => !values.includes(`${field.name}.${nestedField.name}`))
        );
        setSelectedComplexFields(selectedComplexFields.filter(value =>
          !updatedComplexFields.includes(value)
        ));
      };
    
      const handleComplexFieldChange = (values) => {
        // Update the selected complex fields
        setSelectedComplexFields(values);
    
        // Ensure selected complex fields are not included in primitive field selections
        const updatedPrimitiveFields = primitiveFields.filter(field =>
          !values.includes(field.name)
        );
        setSelectedPrimitiveFields(selectedPrimitiveFields.filter(value =>
          !updatedPrimitiveFields.includes(value)
        ));
      };

     

     
  
    return (


              <>
 
                      <div style={{padding: '20px',background:'#f5f5f5',height:'100vh',overflowY:'auto'}}>
                      <div >
                        <Select
                          placeholder="Select a project"
                          onChange={handleProjectChange}
                          style={{ width: '50%',textAlign:'none' }}
                        >
                          {Projects?.map((project) => (
                            <Option key={project.id} value={project.id}>
                              {project.name}
                            </Option>
                          ))}
                        </Select>
                      </div>

                      <div style={{ marginTop: '20px',}}>
                          <Input
                            placeholder="Enter token"
                            value={token}
                            onChange={handleTokenChange}
                            style={{width: '50%',display:'block'}}
                          />
                        <Select
                        showSearch
                        placeholder="Select a query"
                        onChange={handleQueryChange}
                        style={{ width: '50%', marginBottom: '10px' ,marginTop: '20px' }}
                        filterOption={(input, option) =>
                          option.children.toLowerCase().includes(input.toLowerCase())
                        }
                      >
                        {queries.map((query) => (
                          <Option key={query._id} value={query.name}>
                            {query.name}
                          </Option>
                        ))}
                      </Select>
                      <div style={{border: '1px solid #ccc', padding: '10px', marginBottom: '10px',overflowY: 'auto'}}>
                              <strong>Details:</strong>
                              <pre style={{lineHeight: '1.5',textAlign:'left'}}>{<div dangerouslySetInnerHTML={{__html: querydetails}}/>}</pre>
                              
                          </div>
                        {selectedQuery && selectedQuery.variables && (
                          <div style={{ height:'80vh',overflowY: 'auto',border: '1px solid #ccc', padding: '10px', marginBottom: '10px',width:'100vh',overflowX:'hidden'}}>
                            {selectedQuery.variables.map((variable) => (
                              <div key={variable.name} style={{ marginBottom: '10px', }}>
                                {renderVariableInput(variable)}
                              </div>
                            ))}
                          </div>
                        )}
                        {selectedQuery && (
                          <div>
                            <Select
                              mode="multiple"
                              placeholder="Select primitive fields"
                              value={selectedPrimitiveFields}
                              onChange={handlePrimitiveFieldChange}
                              style={{ width: '50%', marginTop: '10px' }}
                            >
                              {primitiveFields.map((field, index) => (
                                <Option key={index} value={field.name}>
                                  {field.name}
                                </Option>
                              ))}
                            </Select>
                            <Select
                              mode="multiple"
                              placeholder="Select complex fields"
                              value={selectedComplexFields}
                              onChange={handleComplexFieldChange}
                              style={{ width: '50%', marginTop: '10px' }}
                            >
                              {complexFields.map((field, index) => (
                                <OptGroup key={index} label={field.name}>
                                  {field.fields.map((nestedField, nestedIndex) => (
                                    <Option key={`${field.name}.${nestedIndex}`} value={nestedField.name}>
                                      {nestedField.name}
                                    </Option>
                                  ))}
                                </OptGroup>
                              ))}
                            </Select>
                          </div>
                        )}
                        <Button type="primary" style={{ marginTop: '10px'}} onClick={() => constructQuery()}>
                          Construct Query
                        </Button>
                      </div>
                      <div>

                      
                      
                      {/* 
                      <Row gutter={[16, 16]} style={{ marginTop: '10px' }}>
                        <Col xs={24} md={8}>
                          <div style={{ border: '1px solid #ccc', padding: '10px', marginBottom: '10px' }}>
                            <strong>Constructed Query:</strong>
                            <pre style={{ lineHeight: '1.5', textAlign: 'left', overflowY: 'auto', height: '400px' }}>
                              {constructedQuery}
                            </pre>
                          </div>
                        </Col>
                        <Col xs={24} md={8}>
                          <div style={{ border: '1px solid #ccc', padding: '10px', marginBottom: '10px' }}>
                            <strong>Variables:</strong>
                            <pre style={{ lineHeight: '1.5', textAlign: 'left', overflowY: 'auto', height: '400px' }}>
                              {inputVariablesString}
                            </pre>
                          </div>
                        </Col>
                        <Col xs={24} md={8}>
                          <div style={{ border: '1px solid #ccc', padding: '10px', marginBottom: '10px' }}>
                            <strong>API Response:</strong>
                            <pre style={{ lineHeight: '1.5', textAlign: 'left', overflowY: 'auto', height: '400px' }}>
                              {apiResponse}
                            </pre>
                          </div>
                        </Col>
                      </Row> */}

                      

                      <Row gutter={[16, 16]} style={{ marginTop: '10px' }}>
                        <Col xs={24} md={8}>
                        <Button 
                              icon={<CopyOutlined />} 
                              onClick={() => copyToClipboard(constructedQuery)} 
                              style={{ marginTop: '10px' }}
                            >
                              Copy
                            </Button>
                          <div style={{ border: '1px solid #ccc', padding: '10px', marginBottom: '10px',overflowY: 'auto', height: '500px' }}>
                            <strong>Constructed Query:</strong>
                            <TextArea
                              value={constructedQuery}
                              onChange={(e) => setConstructedQuery(e.target.value)} // Update state for query
                              style={{ lineHeight: '1.5', textAlign: 'left' }}
                              autoSize={{ minRows: 20, maxRows: 200 }} // Adjust textarea size
                            />
                            
                          </div>
                        </Col>
                        
                        <Col xs={24} md={8}>
                        <Button 
                              icon={<CopyOutlined />} 
                              onClick={() => copyToClipboard(inputVariablesString)} 
                              style={{ marginTop: '10px' }}
                            >
                              Copy
                            </Button>
                          <div style={{ border: '1px solid #ccc', padding: '10px', marginBottom: '10px',height: '500px',overflowY: 'auto',  }}>
                            <strong>Variables:</strong>
                            <TextArea
                              value={inputVariablesString}
                              onChange={(e) => setInputVariablesString(e.target.value)} // Update state for variables
                              style={{ lineHeight: '1.5', textAlign: 'left' }}
                              autoSize={{ minRows: 20, maxRows: 200 }}
                            />
                            
                          </div>
                        </Col>
                        
                        <Col xs={24} md={8}>
                        <Button 
                              icon={<CopyOutlined />} 
                              onClick={() => copyToClipboard(apiResponse)} 
                              style={{ marginTop: '10px' }}
                            >
                              Copy
                            </Button>
                          <div style={{ border: '1px solid #ccc', padding: '10px', marginBottom: '10px', overflowY: 'auto', height: '500px' }}>
                            <strong>API Response:</strong>
                            <pre style={{ lineHeight: '1.5', textAlign: 'left' }}>
                              {apiResponse}
                            </pre>
                          </div>
                          
                        </Col>
                      </Row>




                        

                        <Button type="primary" onClick={handleRunQuery}>
                            Run Query
                          </Button>
                                
                      </div>
                      </div>

              </>
 
  
 
    );
  };
  
  
  export default Home;