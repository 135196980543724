import React, {  useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Input, Button, Select, Typography, Row, Col,Spin } from 'antd';
import 'antd/dist/reset.css';
import { useMutation, useLazyQuery } from '@apollo/client';
import LOGIN_MUTATION from '../graphql/mutation/Login';
import ME_QUERY from '../graphql/queries/Me';
import logo from '../rlogo.png';

const { Title } = Typography;
const { Option } = Select;

const Login = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [usertype, setusertype] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState(null);



const [getDashboard] = useLazyQuery(ME_QUERY,{
  fetchPolicy: 'network-only', // Ensure fresh data is fetched

    onCompleted: (data) => {
      console.log('data', data);
    sessionStorage.setItem('data', JSON.stringify(data));
    if(data.me.usertype == "SUPERADMIN"){
      navigate('/projects');
    }
    else{
      navigate('/testapis')
    }
   
  },
});

  const handleLogin = (dt) => {
   
   
    console.log('dt', dt);
    let token = dt.login.token;
    let refreshToken = dt.login.refreshToken;
    sessionStorage.setItem('token', token);
    sessionStorage.setItem('refreshToken', refreshToken);
 
    
    let loginCurrentTime = new Date().getTime(); // This would be the timestamp you want to format
    // let loginCurrentTime = timestamp.toLocaleTimeString();
    sessionStorage.setItem(
        'loginCurrentTime',
        JSON.stringify(loginCurrentTime)
    );
        getDashboard();

};

  const [login, { loading, error }] = useMutation(LOGIN_MUTATION,{
    onCompleted: handleLogin,
    onError: (error) => {
      setLoginError(error.message);
    },
  });


  if (loading) {
    return (
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh', // Full viewport height
      }}>
        <Spin size="large" />
      </div>
    );
  }
  // if (error) {
  //   return <h1>Error...</h1>;
  // }



  const handleSubmit = async () => {
    login({
      variables: {
        username: username,
        password: password,
        usertype: usertype,
      },
    });
  };

 




  
  return (
    <div style={{
      height: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#f0f2f5', // Optional background color
    }}>
      <div style={{
        width: '100%',
        maxWidth: '400px',
        padding: '20px',
        backgroundColor: '#fff',
        boxShadow: '0 0 15px rgba(0, 0, 0, 0.1)',
        textAlign: 'center',
        borderRadius: '8px',
      }}>
        <div style={{ marginBottom: '20px' }}>
          <img src={logo} alt="Krishitantra Logo" style={{ width: '100px', marginBottom: '10px' }} />
          <h2><strong>Krishitantra</strong></h2>
        </div>

        <Form
          name="login"
          style={{margin: '0 auto',width: '100%'}}
          initialValues={{ remember: true }}
          onFinish={handleSubmit}
        >
          <Form.Item
            label="Username"
            name="username"
            rules={[{ required: true, message: 'Please input your username!' }]}
          >
            <Input
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </Form.Item>

          <Form.Item
            label="User Type"
            name="usertype"
            rules={[{ required: true, message: 'Please select your user type!' }]}
          >
            <Select
              value={usertype}
              onChange={(value) => setusertype(value)}
            >
              <Option value="SUPERADMIN">SUPERADMIN</Option>
              <Option value="USER">USER</Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input.Password
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Item>

          {loginError && <p style={{ color: 'red' }}>Invalid credentials</p>}

          <Form.Item>
            <Button type="primary" htmlType="submit" block loading={loading}>
              Login
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Login;
