import React, { useState, useEffect } from 'react';
import { Upload, Button, Form, message, Row, Col, Select, Progress } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import UPLOAD_APIS from '../graphql/mutation/uploadApis';
import GET_PROJECTS from '../graphql/queries/getProjects';

const { Option } = Select;

const BulkUpload = () => {
  const [jsonData, setJsonData] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);

  const { loading, error, data } = useQuery(GET_PROJECTS,{  fetchPolicy: 'network-only', // Ensure fresh data is fetched
  });
  const [uploadApis] = useMutation(UPLOAD_APIS, { errorPolicy: 'none' });

  const projects = data?.getProjects || [];

  const handleUpload = (file) => {
    const isJson = file.type === 'application/json';
    if (!isJson) {
      message.error('You can only upload JSON files!');
      return false;
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const json = JSON.parse(e.target.result);
        // setJsonData({ queries: json.queries, mutations: json.mutations });
        setJsonData(json);
        message.success('File uploaded successfully!');
      } catch (err) {
        console.error(err);
        message.error('Invalid JSON file');
      }
    };
    reader.readAsText(file);

    return false;
  };

//   const handleSubmit = async () => {
//     if (!jsonData) {
//       message.error('Please upload a JSON file before submitting');
//       return;
//     }

//     if (!selectedProject) {
//       message.error('Please select a project before submitting');
//       return;
//     }

//     setIsUploading(true);
//     setUploadProgress(0);

//     try {
  
//       // const totalApis = (jsonData.queries.length || 0) + (jsonData.mutations.length || 0);
//       // console.log('Uploading', totalApis, 'APIs');

//       const uploadApi = async (apiData, type) => {
//         const { name, variables, fields } = apiData;
//         await uploadApis({
//           variables: {
//             // input: {
//             //   name: name,
//             //   project: selectedProject,
//             //   query: type,
//             //   variables: variables,
//             //   fields: fields
//             // }
//           }
//         });
//       };

//       let count = 0;
//     for (let i = 0; i < jsonData.queries.length; i++) {
//       await uploadApi(jsonData.queries[i], 'query');
//       count++;
//       setUploadProgress(Math.round((count / totalApis) * 100));
//     }

//     for (let i = 0; i < jsonData.mutations.length; i++) {
//       await uploadApi(jsonData.mutations[i], 'mutation');
//       count++;
//       setUploadProgress(Math.round((count / totalApis) * 100));
//     }

//     message.success('All APIs uploaded successfully!');
//   } catch (error) {
//     message.error(`Failed to upload API: ${error.message}`);
//     console.error(error);
//   } finally {
//     setIsUploading(false);
//   }
// };


const handleSubmit = async () => {
  if (!jsonData) {
    message.error('Please upload a JSON file before submitting');
    return;
  }

  if (!selectedProject) {
    message.error('Please select a project before submitting');
    return;
  }

  setIsUploading(true);
  setUploadProgress(0);

  try {
    // Upload the entire jsonData in one go with the project ID
    await uploadApis({
      variables: {
        input: jsonData,
        projectsId: selectedProject
      }
    });

    message.success('JSON data uploaded successfully!');
  } catch (error) {
    message.error(`Failed to upload JSON data: ${error.message}`);
    console.error(error);
  } finally {
    setIsUploading(false);
  }
};


  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading projects</p>;

  return (
    <Form onFinish={handleSubmit}>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            label="Select Project"
            name="project"
            rules={[{ required: true, message: 'Please select a project' }]}
          >
            <Select onChange={setSelectedProject}>
              {projects?.map((project) => (
                <Option key={project.id} value={project.id}>
                  {project.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="File Upload"
            valuePropName="fileList"
          >
            <Upload
              accept=".json"
              showUploadList={false}
              beforeUpload={handleUpload}
            >
              <Button icon={<UploadOutlined />}>Upload JSON</Button>
            </Upload>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item>
            <Button type="primary" htmlType="submit" disabled={isUploading}>
              Submit
            </Button>
          </Form.Item>
        </Col>
        {isUploading && (
          <Col span={24}>
            <Progress percent={uploadProgress} />
          </Col>
        )}
      </Row>
    </Form>
  );
};

export default BulkUpload;
