import React, { useState, useEffect } from 'react';
import { Form, Button, Select, message, Spin, Card } from 'antd';
import { useQuery, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import GET_USER from '../../graphql/queries/getUser'; // Import GET_USER query
import UPDATE_USER from '../../graphql/mutation/UpdateUser';
import GET_PROJECTS from '../../graphql/queries/getProjects';
import GET_APIS from '../../graphql/queries/getApis';
import { useNavigate } from 'react-router-dom';

const { Option } = Select;

const AddProject = () => {
  const navigate = useNavigate();
  const userId  = useParams(); // Get the userId from the URL parameters
  // console.log('userId', userId);
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [selectedApiIds, setSelectedApiIds] = useState([]);
  const [allApis, setAllApis] = useState([]);

  const { loading: userLoading, error: userError, data: userData } = useQuery(GET_USER, {  fetchPolicy: 'network-only', // Ensure fresh data is fetched

    variables: { id: userId.id },
  });
  const { loading: projectsLoading, error: projectsError, data: projectsData } = useQuery(GET_PROJECTS,{  fetchPolicy: 'network-only', // Ensure fresh data is fetched
  });
  const { loading: apisLoading, error: apisError, data: apisData } = useQuery(GET_APIS, {
    fetchPolicy: 'network-only', // Ensure fresh data is fetched

    skip: !selectedProjectId, // Skip the query if no project is selected
    variables: { projectid: selectedProjectId },
  });
  const [editUser] = useMutation(UPDATE_USER);
  const [form] = Form.useForm();

  // Handle the 'Select All' for APIs
 // Handle the 'Select All' for APIs
 const handleSelectAllApis = () => {
    const allApiIds = allApis.map(api => api.id);
    setSelectedApiIds(allApiIds);
    form.setFieldsValue({ apiIds: allApiIds }); // Update form value
  };
  // Handle the 'Deselect All' for APIs
  const handleDeselectAllApis = () => {
    setSelectedApiIds([]);
  };

  const handleProjectChange = (projectId) => {
    setSelectedProjectId(projectId);
  };

  const handleApiChange = (apiIds) => {
    setSelectedApiIds(apiIds);
  };

  const onFinish = async (values) => {
    try {
      // Merge old and new data
      const updatedProjects = selectedProjectId ? [...(userData.getUser.projects || []), selectedProjectId] : [...(userData.getUser.projects || [])];
      const updatedApis = [...(userData.getUser.apis || []), ...selectedApiIds];

      await editUser({
        variables: {
          updateUserId: userId.id,
          projects: updatedProjects,
          apis: updatedApis,
        },
      });
      message.success('Project and API added successfully!');

      form.resetFields();
      navigate('/users');
    } catch (err) {
      message.error('Failed to add project and API.');
    }
  };

  useEffect(() => {
    // Update allApis when apisData changes
    if (apisData?.getApis) {
      setAllApis(apisData.getApis);
    }
  }, [apisData]);

  // if (userLoading || projectsLoading || (selectedProjectId && apisLoading)) return <Spin size="large" />;
  // if (userError || projectsError || apisError) return <div>Error: {userError?.message || projectsError?.message || apisError?.message}</div>;

  const user = userData?.getUser;
  const projects = projectsData?.getProjects;
  const apis = apisData?.getApis || []; // Ensure `apis` is defined

  return (
    <div style={{ padding: '24px' }}>
      <h2>Add Project and API to User</h2>

      {/* Display user details */}
      <Card title="User Details" style={{ marginBottom: '24px' }}>
        <p><strong>Username:</strong> {user?.username}</p>
      </Card>

      {/* Form for adding project and API */}
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item
          label="Select Project"
          name="projectId"
          rules={[{ required: true, message: 'Please select a project!' }]}
        >
          <Select
            placeholder="Select a project"
            value={selectedProjectId}
            onChange={handleProjectChange}
            allowClear
          >
            {projects?.map(project => (
              <Option key={project.id} value={project.id}>
                {project.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Select APIs"
          name="apiIds"
          rules={[{ required: true, message: 'Please select APIs!' }]}
        >
          <Select
            showSearch
            mode="multiple"
            placeholder="Select APIs"
            value={selectedApiIds}
            onChange={handleApiChange}
            allowClear
            filterOption={(input, option) => {
              // Check if option.children is a string before applying .toLowerCase()
              if (typeof option.children === 'string') {
                return option.children.toLowerCase().includes(input.toLowerCase());
              }
              return false; // If it's not a string, don't filter it in
            }}

          >
            {/* Custom 'Select All' option */}
            {selectedProjectId && (
              <Option key="select-all" value="select-all" disabled>
                <Button type="default" onClick={handleSelectAllApis} style={{ width: '100%' }}>
                  Select All APIs
                </Button>
              </Option>
            )}
            {/* API options */}
            {apis?.map(api => (
              <Option key={api.id} value={api.id}>
                {api.name}
              </Option>
            ))}
            {/* Custom 'Deselect All' option */}
            {selectedProjectId && (
              <Option key="deselect-all" value="deselect-all" disabled>
                <Button type="default" onClick={handleDeselectAllApis} style={{ width: '100%' }}>
                  Deselect All APIs
                </Button>
              </Option>
            )}
          </Select>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Add Project and API
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AddProject;
