// src/components/CreateUserForm.jsx
import React from 'react';
import { useMutation } from '@apollo/client';
import { Form, Input, Button, Select,notification } from 'antd';
import CREATE_USER  from '../../graphql/mutation/createUser';
import './user.css';
const { Option } = Select;


const CreateUserForm = () => {
  const [form] = Form.useForm();
  const [createUser, { loading }] = useMutation(CREATE_USER);

  const onFinish = async (values) => {
    try {
     // Ensure usertype is an array
     const formattedValues = { ...values, usertype: [values.usertype],projects:[],apis:[] };
     const { data } = await createUser({ variables: { user: formattedValues } });
      notification.success({
        message: 'User Created',
        description: `User ${data.CreateUser.name} was created successfully.`,
      });
      form.resetFields();
    } catch (error) {
      notification.error({
        message: 'Error',
        description: error.message,
      });
    }
  };

  return (
    <div className="form-container">
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      initialValues={{
        name: '',
        phone: '',
        username: '',
        email: '',
        usertype: [],

      }}
    >
      <Form.Item
        name="name"
        label="Name"
        rules={[{ required: true, message: 'Please enter your name' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="phone"
        label="Phone"
        rules={[{ required: true, message: 'Please enter your phone number' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="username"
        label="Username"
        rules={[{ required: true, message: 'Please enter your username' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="email"
        label="Email"
        rules={[
          { required: true, message: 'Please enter your email' },
          { type: 'email', message: 'Please enter a valid email' },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
          name="usertype"
          label="User Type"
          rules={[{ required: true, message: 'Please select a user type' }]}
        >
          <Select>
            <Option value="USER">USER</Option>
          
          </Select>
        </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" loading={loading}>
          Create User
        </Button>
      </Form.Item>
    </Form>
  </div>
  );
};

export default CreateUserForm;
