import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import createUploadLink from "apollo-upload-client/createUploadLink.mjs";
import App from './App';


const httpLink = createUploadLink({
  uri:'https://apidocumentation-api-dev.krishitantra.com',
// uri:'http://localhost:5000/',
});
const authLink = setContext((_, { headers }) => {
	// get the authentication token from session storage if it exists
	const token = sessionStorage.getItem('token');
	// return the headers to the context so httpLink can read them
	return {
		headers: {
			...headers,
			'Apollo-Require-Preflight': true,
			authorization: token ? `bearer ${token}` : '',
		},
	};
});

const client=new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
})
ReactDOM.render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>,
  document.getElementById('root')
);
