import { gql } from "@apollo/client";

export default gql

`query GetProjects {
    getProjects {
      id
      url
      description
      name
    }
  }
`