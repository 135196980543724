import { gql } from "@apollo/client";

export default gql
`query Me {
  me {
    ... on User {
      usertype
      username
      name
      phone
      id
      email
      region
      details
    }
  }
}
`