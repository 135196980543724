import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Spin, Typography, Empty, Avatar, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';

import GET_PROJECTS from '../../graphql/queries/getProjects';
import GET_USERS from '../../graphql/queries/getUsers';
import GET_APIS from '../../graphql/queries/getApis';
import { useNavigate } from 'react-router-dom';

const { Title } = Typography;

const ProjectsPage = () => {
  const navigate = useNavigate();
  const { loading: projectsLoading, error: projectsError, data: projectsData } = useQuery(GET_PROJECTS,{  
  });
  const { loading: usersLoading, error: usersError, data: usersData } = useQuery(GET_USERS,{  
  });
  const { loading: apisLoading, error: apisError, data: apisData } = useQuery(GET_APIS,{ 
  });

  const [projectsWithCounts, setProjectsWithCounts] = useState([]);

  useEffect(() => {
    if (projectsData && usersData && apisData) {
      const projects = projectsData.getProjects;
      const users = usersData.getUsers;
      const apis = apisData.getApis;

      const updatedProjects = projects.map((project) => {
        console.log(users)
        const projectUsers = users.filter(user => user.projects.includes(project.id));
        const projectApis = apis.filter(api => api.project === project.id);

        return {

          ...project,
          userCount: projectUsers.length,
          apiCount: projectApis.length,
        };
      });

      setProjectsWithCounts(updatedProjects);
    }
  }, [projectsData, usersData, apisData]);

  if (projectsLoading || usersLoading || apisLoading){
    return (
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh', // Full viewport height
      }}>
        <Spin size="large" />
      </div>
    );
  }
  // if (projectsError || usersError || apisError) return <div>Error: {projectsError?.message || usersError?.message || apisError?.message}</div>;

  return (
    <>
    <div style={{ padding: '24px' }}>
      <Title level={2}>Projects Overview</Title>
      {projectsWithCounts.length === 0 ? (
        <>
          <Empty description="No Projects Found" />
          <Col xs={24} sm={12} md={8}>
            <Card
              hoverable
              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}
              onClick={() => navigate('/createProject')}
            >
              <PlusOutlined style={{ fontSize: '2rem' }} />
            </Card>
          </Col>
        </>
      ) : (
        <Row gutter={[16, 16]}>
          {projectsWithCounts.map((project) => (
            <Col xs={24} sm={12} md={8} key={project.id}>
              <Card
                title={<span style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>{project.name}</span>}
                hoverable
                onClick={() => window.location.href = `/projects/${project.id}`}
              >
                <p style={{ fontSize: '1.2rem' }}>Users: {project.userCount}</p>
                <p style={{ fontSize: '1.2rem' }}>APIs: {project.apiCount}</p>
              </Card>
            </Col>
          ))}
          <Col xs={24} sm={12} md={8}>
            <Card
              hoverable
              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}
              onClick={() => navigate('/createProject')}
            >
              <PlusOutlined style={{ fontSize: '2rem' }} />
            </Card>
          </Col>
        </Row>
      )}
    </div>
  </>
  
  );
};

export default ProjectsPage;
