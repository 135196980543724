import React, { useEffect, useState } from 'react';
import { Table, Button, Space } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import GET_USERS from '../../graphql/queries/getUsers';

const User = () => {
  const navigate = useNavigate();
  const { loading, error, data } = useQuery(GET_USERS,{  fetchPolicy: 'network-only', // Ensure fresh data is fetched
  });
  const [users, setUsers] = useState([]);

  useEffect(() => {
    if (data && data.getUsers) {
      const transformedData = data.getUsers?.map(user => ({
        key: user.id,
        username: user.username,
        email: user.email,

      }));
      setUsers(transformedData);
    }
  }, [data]);

  // Define the columns
  const columns = [
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
   
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <Button type="primary" onClick={() => handleAddProject(record.key)}>Add Project</Button>
          {/* <Button type="primary" onClick={() => handleAddAPI(record.key)}>Add API</Button> */}
        </Space>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <Button type="primary" onClick={() => handleEditUser(record.key)}>Edit User</Button>
        </Space>
      ),
    }
  ];

  // Handlers for action buttons
  const handleAddProject = (key) => {
    navigate(`/addProject/${key}`);
  };
  const handleEditUser = (key) => {
    navigate(`/editUser/${key}`);
  };
  

  const handleAddAPI = (key) => {
    console.log(`Add API for user with key: ${key}`);
  };

  if (loading) return <p>Loading...</p>;
//   if (error) return <p>Error: {error.message}</p>;

  return (
    <div style={{ padding: '24px' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}>
        <h2>Users</h2>
        <Button type="primary" onClick={() => navigate('/createuser')} icon={<PlusOutlined />} >
          Create User
        </Button>
      </div>
      <Table dataSource={users} columns={columns} />
    </div>
  );
};

export default User;
