// TextEditor.tsx
import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
 
const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
     
        // { indent: "-1" },
        // { indent: "+1" },
    ],
    ["link", "code"],
    ["clean"],
  
  ],
};
 
const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
 
  "bullet",
  "link",
  "code",
];
 


 
 
const TextEditor = ({ value, onChange, placeholder }) => {

  const handleContentChange = (content) => {
    // Replace tabs with four non-breaking spaces
    let formattedContent = content.replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;');
    
    // Replace multiple spaces with corresponding non-breaking spaces
    formattedContent = formattedContent.replace(/ +/g, (match) => {
      return '&nbsp;'.repeat(match.length);
    });
  
    onChange(formattedContent);
  };
 
  return (
    <>
      <ReactQuill
        theme="snow"
        value={value || ""}
        modules={modules}
        formats={formats}
        onChange={handleContentChange}
        placeholder={placeholder}
        style={{ backgroundColor: 'white',borderRadius:'10px' }} // Set background color to white

      />
    </>
  );
};
 
export default TextEditor;










// // TextEditor.tsx
// import React, { useEffect } from "react";
// import ReactQuill, { Quill } from "react-quill";
// import "react-quill/dist/quill.snow.css";
// import QuillBetterTable from "quill-better-table";

// // Register the table module
// Quill.register(
//   {
//     "modules/better-table": QuillBetterTable,
//   },
//   true
// );

// const modules = {
//   toolbar: [
//     [{ header: [1, 2, false] }],
//     ["bold", "italic", "underline", "strike", "blockquote"],
//     [
//       { list: "ordered" },
//       { list: "bullet" },
//     ],
//     ["link", "code"],
//     ["clean"],
//     ["table"], // Add table option in the toolbar
//   ],
//   "better-table": {
//     operationMenu: {
//       items: {
//         insertColumnRight: {
//           text: "Insert column right",
//         },
//         insertColumnLeft: {
//           text: "Insert column left",
//         },
//         insertRowUp: {
//           text: "Insert row above",
//         },
//         insertRowDown: {
//           text: "Insert row below",
//         },
//         mergeCells: {
//           text: "Merge selected cells",
//         },
//         unmergeCells: {
//           text: "Unmerge selected cells",
//         },
//         deleteColumn: {
//           text: "Delete column",
//         },
//         deleteRow: {
//           text: "Delete row",
//         },
//       },
//     },
//   },
//   clipboard: {
//     matchVisual: false,
//   },
// };

// const formats = [
//   "header",
//   "bold",
//   "italic",
//   "underline",
//   "strike",
//   "blockquote",
//   "list",
//   "bullet",
//   "link",
//   "code",
//   "table", // Add table to formats
// ];

// const TextEditor = ({ value, onChange, placeholder }) => {
//   const handleContentChange = (content) => {
//     let formattedContent = content.replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;');
//     formattedContent = formattedContent.replace(/ +/g, (match) => {
//       return '&nbsp;'.repeat(match.length);
//     });

//     onChange(formattedContent);
//   };

//   return (
//     <>
//       <ReactQuill
//         theme="snow"
//         value={value || ""}
//         modules={modules}
//         formats={formats}
//         onChange={handleContentChange}
//         placeholder={placeholder}
//         style={{ backgroundColor: "white", borderRadius: "10px" }}
//       />
//     </>
//   );
// };

// export default TextEditor;
