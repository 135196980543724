import { gql } from "@apollo/client";

export default gql
`
mutation CreateProject($input: ProjectInput) {
    CreateProject(input: $input) {
      description
      id
      name
      url
    }
  }
`