import React from 'react';

import Routers from './router';

const App = () => {

  return (
    < div style={{ height: '100vh' }}>
      <Routers />
    </div>
  );
};

export default App;
