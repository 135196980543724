// src/ProfilePage.js
import React, { useEffect, useState } from 'react';
import { Card, Descriptions, Typography } from 'antd';
import 'antd/dist/reset.css'; // Import Ant Design styles by using reset.css
 

const { Title } = Typography;

const ProfilePage = () => {
  const [userData, setUserData] = useState({});

  useEffect(() => {
    const data = JSON.parse(sessionStorage.getItem('data'));
    console.log('data',data)
    if (data) {
      setUserData(data.me);
    }
  }, []);

  return (
    <Card title="User Profile" style={{ maxWidth: 600, margin: 'auto' }}>
      <Descriptions bordered column={1}>
        <Descriptions.Item label="Name">{userData.name}</Descriptions.Item>
        <Descriptions.Item label="Email">{userData.email}</Descriptions.Item>
        <Descriptions.Item label="Phone">{userData.phone}</Descriptions.Item>
      </Descriptions>
    </Card>
  );
};

export default ProfilePage;
